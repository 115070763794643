<div class="
        flex
        justify-content-center
        h-screen
        w-screen
        align-items-center
        py-8
    ">

  <div class="w-12">

    <div class="text-3xl ml-6 mb-3 logo-text">
      MinimalTools
    </div>

    <div class="
                w-full
                login-container
                pt-8
                pb-8
                flex
                justify-content-center
            ">

      <div class="w-11 sm:w-5 md:w-4 lg:w-3 text-center">

        <div class="flex flex-column align-items-center text-white mb-8">
          <div class="text-8xl">
            404
          </div>
          <div class="text-3xl">
            page not found
          </div>
        </div>




      </div>

    </div>

  </div>

</div>