import { AuthConfig } from 'angular-oauth2-oidc'

const baseConfig: Partial<AuthConfig> = {
    redirectUri: window.location.origin,
    responseType: 'code',
    oidc: true,
    scope: 'openid profile email offline_access urn:zitadel:iam:org:project:id:zitadel:aud',
    postLogoutRedirectUri: window.location.origin,
}

export const authCodeFlowConfig: AuthConfig = {
    ...baseConfig,
    issuer: 'https://login.minimaltools.ch',
    clientId: '256986952639836113@minimaltools',
}

export const devAuthCodeFlowConfig: AuthConfig = {
    ...baseConfig,
    showDebugInformation: true,
    issuer: 'https://minimaltoolstest-aokiyi.zitadel.cloud',
    clientId: '253029060551192962@minimaltools',
}

