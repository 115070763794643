import { Injectable } from '@angular/core'
import { CanActivate } from '@angular/router'
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service'

@Injectable({
    providedIn: 'root',
})
export class LoginGuard implements CanActivate {
    constructor(private authService: AuthenticationService) {}

    async canActivate() {
        if (!this.authService.authenticated) {
            return await this.authService.authenticate(true)
        }
        return true
    }
}
