import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { UserService } from '../services/user.service'
import { AuthenticationService } from 'src/app/modules/core/services/authentication.service'

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private authService: AuthenticationService, private userService: UserService) {}

    async canActivate(route: ActivatedRouteSnapshot) {
        if (!this.authService.authenticated) {
            console.info('no access token, redirecting to login')
            return await this.authService.authenticate(true)
        }

        let user = await this.userService.getUser()

        if (!user) {
            console.error('no user, redirecting to login')
            return await this.authService.authenticate(true)
        }

        if (
            route.routeConfig?.path != '' &&
            !user.allowedRoutes.includes(route.routeConfig?.path!.split('/')[0]!) &&
            user.homePage != route.routeConfig?.path
        ) {
            console.error('not allowed, redirecting to home')
            this.router.navigate(['/home'])
            return false
        }

        return true
    }
}
