import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { CoreModule } from './modules/core/core.module'

const routes: Routes = [{ path: '', loadChildren: () => CoreModule }]

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
