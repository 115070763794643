import { ErrorHandler, Injectable } from '@angular/core'
import * as Sentry from '@sentry/angular-ivy'

@Injectable({
    providedIn: 'root',
})
export class ErrorHandlerService implements ErrorHandler {
    sentryErrorHandler = Sentry.createErrorHandler({
        showDialog: false,
    })
    handleError(error: any) {
        this.sentryErrorHandler.handleError(error)

        if (error.message?.includes('Loading chunk') && error.message?.includes('failed')) {
            console.warn('Chunk loading failed, reloading application...', error)
            window.location.reload()
            return
        }
    }
}
