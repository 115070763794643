import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule } from '@angular/forms'
import { FormsModule } from '@angular/forms'

//primeng modules
import { ButtonModule } from 'primeng/button'
import { PasswordModule } from 'primeng/password'
import { InputTextModule } from 'primeng/inputtext'
import { ToolbarModule } from 'primeng/toolbar'
import { SelectButtonModule } from 'primeng/selectbutton'
import { DropdownModule } from 'primeng/dropdown'
import { SidebarModule } from 'primeng/sidebar'
import { AvatarModule } from 'primeng/avatar'
import { BadgeModule } from 'primeng/badge'
import { MenuModule } from 'primeng/menu'
import { TabViewModule } from 'primeng/tabview'
import { TableModule } from 'primeng/table'
import { ChartModule } from 'primeng/chart'
import { TooltipModule } from 'primeng/tooltip'
import { CardModule } from 'primeng/card'
import { ProgressSpinnerModule } from 'primeng/progressspinner'
import { SkeletonModule } from 'primeng/skeleton'
import { CalendarModule } from 'primeng/calendar'
import { InputNumberModule } from 'primeng/inputnumber'
import { ConfirmDialogModule } from 'primeng/confirmdialog'
import { ToastModule } from 'primeng/toast'
import { InputSwitchModule } from 'primeng/inputswitch'
import { PanelModule } from 'primeng/panel'
import { DialogModule } from 'primeng/dialog'
import { MessagesModule } from 'primeng/messages'
import { MessageModule } from 'primeng/message'
import { TabMenuModule } from 'primeng/tabmenu'
import { CheckboxModule } from 'primeng/checkbox'
import { FileUploadModule } from 'primeng/fileupload'
import { MultiSelectModule } from 'primeng/multiselect'
import { BreadcrumbModule } from 'primeng/breadcrumb'
import { OverlayPanelModule } from 'primeng/overlaypanel'
import { RadioButtonModule } from 'primeng/radiobutton'
import { InputTextareaModule } from 'primeng/inputtextarea'
import { AutoFocusModule } from 'primeng/autofocus'
import { TreeTableModule } from 'primeng/treetable'
import { StepsModule } from 'primeng/steps'
import { ListboxModule } from 'primeng/listbox'

//ngx modules
import { TranslateModule } from '@ngx-translate/core'

//app components
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component'
import { LoaderComponent } from './components/loader/loader.component'
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component'
import { ModalComponent } from './components/modal/modal.component'
import { AutocompleteOffDirective } from './directives/autocomplete-off.directive'
import { TestComponent } from './components/test/test.component'
import { FormModalComponent } from './components/form-modal/form-modal.component'
import { DocumentsAddEditFormComponent } from './components/documents-add-edit-form/documents-add-edit-form.component'
import { JournalsAddEditFormComponent } from './components/journals-add-edit-form/journals-add-edit-form.component'
import { RisksAddEditFormComponent } from './components/risks-add-edit-form/risks-add-edit-form.component'
import { MeasuresAddEditFormComponent } from './components/measures-add-edit-form/measures-add-edit-form.component'
import { FullscreenFormModalComponent } from './components/fullscreen-form-modal/fullscreen-form-modal.component'

@NgModule({
    declarations: [
        LanguageSelectorComponent,
        LoaderComponent,
        ComingSoonComponent,
        ModalComponent,
        AutocompleteOffDirective,
        TestComponent,
        FormModalComponent,
        DocumentsAddEditFormComponent,
        JournalsAddEditFormComponent,
        RisksAddEditFormComponent,
        MeasuresAddEditFormComponent,
        FullscreenFormModalComponent,
    ],
    imports: [
        CommonModule,
        ButtonModule,
        PasswordModule,
        InputTextModule,
        ToolbarModule,
        SelectButtonModule,
        FormsModule,
        DropdownModule,
        SidebarModule,
        AvatarModule,
        BadgeModule,
        TranslateModule,
        MenuModule,
        TabViewModule,
        TableModule,
        ChartModule,
        TooltipModule,
        CardModule,
        ProgressSpinnerModule,
        SkeletonModule,
        ReactiveFormsModule,
        CalendarModule,
        InputNumberModule,
        ConfirmDialogModule,
        ToastModule,
        InputSwitchModule,
        PanelModule,
        DialogModule,
        MessagesModule,
        MessageModule,
        TabMenuModule,
        CheckboxModule,
        FileUploadModule,
        MultiSelectModule,
        BreadcrumbModule,
        OverlayPanelModule,
        AutoFocusModule,
        TreeTableModule,
        RadioButtonModule,
        InputTextareaModule,
        StepsModule,
        ListboxModule,
    ],
    exports: [
        ButtonModule,
        PasswordModule,
        InputTextModule,
        ToolbarModule,
        SelectButtonModule,
        FormsModule,
        DropdownModule,
        SidebarModule,
        AvatarModule,
        BadgeModule,
        TranslateModule,
        MenuModule,
        TabViewModule,
        TableModule,
        ChartModule,
        LanguageSelectorComponent,
        TooltipModule,
        CardModule,
        ProgressSpinnerModule,
        LoaderComponent,
        SkeletonModule,
        ReactiveFormsModule,
        CalendarModule,
        InputNumberModule,
        ConfirmDialogModule,
        ToastModule,
        ComingSoonComponent,
        ModalComponent,
        InputSwitchModule,
        AutocompleteOffDirective,
        PanelModule,
        DialogModule,
        MessagesModule,
        MessageModule,
        TabMenuModule,
        TestComponent,
        FormModalComponent,
        CheckboxModule,
        DocumentsAddEditFormComponent,
        JournalsAddEditFormComponent,
        RisksAddEditFormComponent,
        MeasuresAddEditFormComponent,
        FileUploadModule,
        MultiSelectModule,
        BreadcrumbModule,
        FullscreenFormModalComponent,
        OverlayPanelModule,
        AutoFocusModule,
        TreeTableModule,
        RadioButtonModule,
        InputTextareaModule,
        StepsModule,
        ListboxModule,
    ],
})
export class SharedModule {}
