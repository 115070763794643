import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'

import { SharedModule } from '../shared/shared.module'

import { TranslateService } from '@ngx-translate/core'

import { CoreRoutingModule } from './core-routing.module'

import { BreakpointObserver } from '@angular/cdk/layout'
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api'

import { DatePipe } from '@angular/common'

@NgModule({
    imports: [CommonModule, CoreRoutingModule, SharedModule],
    providers: [TranslateService, ConfirmationService, MessageService, BreakpointObserver, DatePipe],
})
export class CoreModule {
    constructor(private config: PrimeNGConfig, private translate: TranslateService) {
        this.translate.setDefaultLang('de')

        this.translate.onLangChange.subscribe((event) => {
            localStorage.setItem('lang', event.lang)
            this.config.setTranslation(this.translate.instant('primeng'))
        })
        this.translate.use('de')
    }
}
