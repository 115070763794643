import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { BehaviorSubject } from 'rxjs'

import { environment } from 'src/environments/environment'
import { allowedRoutes } from '../models/allowedRoutes'
import { homePages } from '../models/homePages'
import { menuItems } from '../models/menuItems'
import { User } from '../models/user'

import * as Sentry from '@sentry/angular-ivy'
import mixpanel from 'mixpanel-browser'

@Injectable({
    providedIn: 'root',
})
export class UserService {
    user: BehaviorSubject<User | undefined> = new BehaviorSubject<User | undefined>(undefined)

    constructor(private router: Router, private http: HttpClient) {}

    async getUser() {
        if (this.user.getValue() != undefined) return this.user.getValue()

        await this.getUserData()

        return this.user.getValue()
    }

    async getUserData(): Promise<User | undefined> {
        return new Promise((resolve, reject) => {
            this.http.get<any>(`${environment.apiUrl}/users/me`).subscribe({
                next: (message) => {
                    let user: User = message

                    let homePage: any = undefined

                    homePages.forEach((h) => {
                        if (homePage) return

                        user?.permission_keys.forEach((p) => {
                            if (homePage) return
                            if (h.requiredPermission == p) homePage = h.homePage
                        })
                    })

                    if (!homePage) homePage = '404'

                    user.homePage = homePage

                    user.allowedRoutes = []
                    user.menuItems = []

                    user?.permission_keys.forEach((p) => {
                        user.allowedRoutes.push(
                            ...allowedRoutes
                                .filter((r) => r.requiredPermission == p || r.alternativePermissions?.includes(p))
                                .map((r) => r.route)
                        )
                        //user.menuItems.push(...menuItems.filter(m => m.requiredPermission == p))
                    })

                    menuItems.forEach((m) => {
                        let disabled = true

                        user.permission_keys.forEach((p) => {
                            if (m.requiredPermission == p || m.alternativePermissions?.includes(p)) disabled = false
                            //if (m.requiredPermission == p) user.menuItems.push(m);
                        })

                        m.disabled = disabled
                    })

                    user.menuItems = menuItems
                    Sentry.setUser(user)
                    mixpanel.identify(user.email)

                    this.user.next(user)

                    resolve(user)
                },
                error: (error) => {
                    this.user.next(undefined)
                    reject(undefined)
                },
            })
        })
    }

    unloadUserData() {
        Sentry.setUser(null)
        mixpanel.identify()
        this.user.next(undefined)
    }

    /**
     * Redirects to the home page base on the user`s role
     */
    async goToHomePage() {
        const user = await this.getUser()
        this.router.navigate([`/${user?.homePage}`])
    }
}
