import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable, catchError, from, of, switchMap, throwError } from 'rxjs'
import { AuthenticationService } from '../services/authentication.service'

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error) => {
                if (error.status === 401) {
                    return from(this.authService.clearUser()).pipe(switchMap(() => of(error)))
                }
                return throwError(() => error)
            })
        )
    }
}
