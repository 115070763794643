export let homePages = [
    {
        homePage: 'home',
        requiredPermission: 'home_can_view_home'
    }
];

export class HomePage {
    homePage: string;
    requiredPermission: string;
}