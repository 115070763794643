export let allowedRoutes: AllowedRoute[] = [
    {
        route: 'home',
        requiredPermission: 'home_can_view_home',
    },
    {
        route: 'admin',
        requiredPermission: 'admin_can_view_admin',
    },
    {
        route: 'users',
        requiredPermission: 'admin_can_view_admin',
    },
    {
        route: 'wizard',
        requiredPermission: 'admin_can_view_admin',
    },
    {
        route: 'cockpit',
        requiredPermission: 'cockpit_can_view_cockpit',
    },
    {
        route: 'assessment',
        requiredPermission: 'assessment_can_view_assessment',
    },
    {
        route: 'results',
        requiredPermission: 'results_can_view_all_results',
        alternativePermissions: [
            'results_can_view_role_results',
            'results_can_view_role_results_master',
            'results_can_view_own_results',
        ],
    },
    {
        route: 'risks',
        requiredPermission: 'risks_can_view_risks',
        alternativePermissions: ['risks_can_create_edit_risks'],
    },
    {
        route: 'measures',
        requiredPermission: 'measures_can_view_measures',
    },
    {
        route: 'journals',
        requiredPermission: 'journals_can_view_journals',
    },
    {
        route: 'documents',
        requiredPermission: 'documents_can_view_documents',
    },
    {
        route: 'myaccount',
        requiredPermission: 'home_can_view_home',
    },
    {
        route: 'comingsoon',
        requiredPermission: 'home_can_view_home',
    },
    {
        route: 'assessment-submissions',
        requiredPermission: 'admin_can_view_admin',
    },
    {
        route: 'market-monitoring',
        requiredPermission: 'market_monitoring_can_view_market_monitoring',
    },
]

export class AllowedRoute {
    route: string
    requiredPermission: string
    alternativePermissions?: string[]
}
