import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { ErrorComponent } from './components/error/error.component'
import { AuthGuard } from './helpers/auth.guard'
import { LoginGuard } from './helpers/login.guard'

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('../layout/layout.module').then((m) => m.LayoutModule),
        canActivate: [LoginGuard],
    },
    { path: '404', component: ErrorComponent },
    { path: '**', component: ErrorComponent },
]

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [AuthGuard],
})
export class CoreRoutingModule {}
